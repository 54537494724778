import React from 'react';
import { ListItemButton, ListItemText } from '@mui/material';

class TaskLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: false
        };
        this.getLabelId = this.getLabelId.bind(this);
        this.getLabelName = this.getLabelName.bind(this);
        this.flipSelected = this.flipSelected.bind(this);
    }

    getLabelId() {
        const { label } = this.props;
        return label.labelId;
    }

    getLabelName() {
        const { label } = this.props;
        return label.name;
    }

    flipSelected() {
        if (this.state.selected) {
            this.props.deselectLabel(this.getLabelId());
        } else {
            this.props.selectLabel(this.getLabelId());
        }
        this.setState({
            selected: !this.state.selected
        });
    }

    render() {
        return (
            <ListItemButton key={this.getLabelId()} sx={{ pl: 4, width: '100%' }}
                selected={this.state.selected}
                onClick={this.flipSelected}>
                <ListItemText inset primary={this.getLabelName()} />
            </ListItemButton>
        );
    }
}

export default TaskLabel;
