import './App.css';
import { ACCESS_TOKEN, ID_TOKEN } from './constants/apis';
import TodoHome from './todo/TodoHome';

function loadCognitoTokens() {
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    if (hashParams.has(ACCESS_TOKEN)) {
        localStorage.setItem(ACCESS_TOKEN, hashParams.get(ACCESS_TOKEN));
    }
    if (hashParams.has(ID_TOKEN)) {
        localStorage.setItem(ID_TOKEN, hashParams.get(ID_TOKEN));
    }
}

function App() {
    document.title = "Pragmatic task manager";
    loadCognitoTokens();
    return (
        <div className="App">
            <header className="App-header">
                <TodoHome />
            </header>
        </div>
    );
}

export default App;