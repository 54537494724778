import { METHOD } from "../clients/apiClient";

export const ACCESS_TOKEN = "access_token";
export const ID_TOKEN = "id_token";

export const APIS = {
    "LIST_CATEGORIES": {
        "path": "/category",
        "method": METHOD.GET
    },
    "LIST_LABELS": {
        "path": "/label",
        "method": METHOD.GET
    },
    "LIST_TODOS": {
        "path": "/todo",
        "method": METHOD.GET
    },
    "CREATE_TODO": {
        "path": "/todo",
        "method": METHOD.POST
    },
    "UPDATE_TODO": {
        "path": "/todo",
        "method": METHOD.PUT
    },
    "DELETE_TODO": {
        "path": "/todo/{0}",
        "method": METHOD.DELETE
    },
    "CREATE_CATEGORY": {
        "path": "/category",
        "method": METHOD.POST
    },
    "UPDATE_CATEGORY": {
        "path": "/category",
        "method": METHOD.PUT
    },
    "DELETE_CATEGORY": {
        "path": "/category/{0}",
        "method": METHOD.DELETE
    },
    "CREATE_LABEL": {
        "path": "/label",
        "method": METHOD.POST
    },
    "UPDATE_LABEL": {
        "path": "/label",
        "method": METHOD.PUT
    },
    "DELETE_LABEL": {
        "path": "/label/{0}",
        "method": METHOD.DELETE
    },
}
