import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import React from "react";
import MenuIcon from '@mui/icons-material/Menu';

class TodoAppBar extends React.Component {

    constructor(props) {
        super(props);
        this.openDrawer = this.openDrawer.bind(this);
    }

    openDrawer() {
        this.props.updateDrawerState(true);
    }

    render() {
        return (
            <AppBar position="fixed" open={this.props.drawerOpen}
                sx={{ width: '100%' }}>
                <Toolbar>
                    <IconButton size="medium" onClick={this.openDrawer}
                        sx={{ ml: 2 }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" flex={1}>
                        Pragmatic task manager
                    </Typography>
                </Toolbar>
            </AppBar>
        );
    }
}

export default TodoAppBar;