import { FilterList } from "@mui/icons-material";
import { Collapse, IconButton, List, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CategoriesList from "./categories/CategoriesList";
import LabelsList from "./labels/LabelsList";

class FilterPopover extends React.Component {

    constructor() {
        super();
        this.state = {
            open: false
        };
        this.toggleOpen = this.toggleOpen.bind(this);
    }

    toggleOpen() {
        this.setState({
            open: !this.state.open
        });
    }

    render() {
        const categories = this.props.categories;
        const labels = this.props.labels;
        return (
            <Paper>
                <Box>
                    <IconButton size='small' sx={{
                        bgColor: 'white',
                        right: 0,
                        float: 'right',
                        pr: 3
                    }} onClick={this.toggleOpen}>
                        <FilterList />
                    </IconButton>
                    <Collapse in={this.state.open} timeout="auto">
                        <List component="div" disablePadding>
                            <CategoriesList categories={categories}
                                selectCategory={this.props.selectCategory}
                                deselectCategory={this.props.deselectCategory} />
                            <LabelsList labels={labels}
                                selectLabel={this.props.selectLabel}
                                deselectLabel={this.props.deselectLabel} />
                        </List>

                    </Collapse>
                </Box>
            </Paper>
        );
    }

}

export default FilterPopover;