import { List, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer } from '@mui/material';
import React from 'react';
import { HomeTwoTone, LogoutTwoTone, SettingsTwoTone } from '@mui/icons-material';
import { ACCESS_TOKEN, ID_TOKEN } from '../../constants/apis';
import { getAuthenticationUrl } from '../../clients/apiClient';

class TodoSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentView: "home"
        }
        this.closeDrawer = this.closeDrawer.bind(this);
        this.openDrawer = this.openDrawer.bind(this);
        this.logout = this.logout.bind(this);
    }

    logout = () => {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(ID_TOKEN);
        window.location.replace(getAuthenticationUrl());
    }

    handleHomeSelect = () => {
        this.setState({
            currentView: "home"
        });
        this.props.setView('home');
    }

    handleSettingsSelect = () => {
        this.setState({
            currentView: "settings"
        });
        this.props.setView('settings');
    }

    closeDrawer() {
        this.props.updateDrawerState(false);
    }

    openDrawer() {
        this.props.updateDrawerState(true);
    }

    render() {
        return (
            <SwipeableDrawer anchor="left" open={this.props.drawerOpen}
                onClose={this.closeDrawer}
                onOpen={this.openDrawer}
                sx={{
                    width: `${this.props.drawerWidth}px`,
                    flexShrink: 0,
                    bgcolor: '#d6d6c2',
                    '& .MuiDrawer-paper': {
                        width: `${this.props.drawerWidth}px`,
                        boxSizing: 'border-box',
                    },
                }}>
                <List style={{ width: '100%', bgColor: 'background.paper' }}
                    component="nav">
                    <ListItemButton selected={this.state.currentView === 'home'}
                        sx={{
                            width: '100%'
                        }}
                        onClick={this.handleHomeSelect} key='home'>
                        <ListItemIcon>
                            <HomeTwoTone />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItemButton>
                    <ListItemButton key='setting' onClick={this.handleSettingsSelect}
                        sx={{
                            width: '100%'
                        }}>
                        <ListItemIcon>
                            <SettingsTwoTone />
                        </ListItemIcon>
                        <ListItemText primary='Settings' />
                    </ListItemButton>
                    <ListItemButton key='logout' onClick={this.logout}
                        sx={{
                            width: '100%'
                        }}
                    >
                        <ListItemIcon>
                            <LogoutTwoTone />
                        </ListItemIcon>
                        <ListItemText primary='Logout' />
                    </ListItemButton>
                </List>
            </SwipeableDrawer>
        );
    }
}

export default TodoSidebar;
