import React from 'react';
import TaskCategory from './TaskCategory';
import { ListItemIcon, ListItemText, Collapse, List } from '@material-ui/core';
import { CategoryRounded, ExpandLess, ExpandMore } from '@material-ui/icons';
import { Box, ListItemButton } from '@mui/material';

class CategoriesList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
        this.handleItemSelect = this.handleItemSelect.bind(this);
        this.handleAddCategory = this.handleAddCategory.bind(this);
    }

    handleItemSelect = () => {
        this.setState({
            open: !this.state.open
        });
    };

    handleAddCategory() {
        var textField = document.getElementById('add-category-text');
        const categoryName = textField.value;
        if (categoryName === "") {
            return;
        }
        this.props.addCategory(categoryName);
        textField.value = "";
    }

    render() {
        const { categories } = this.props;
        return (
            <Box sx={{ pl: 2 }}>
                <ListItemButton onClick={this.handleItemSelect} key='categoriesSidebar'
                    sx={{ width: '100%' }}>
                    <ListItemIcon>
                        <CategoryRounded />
                    </ListItemIcon>
                    <ListItemText primary="Categories" />
                    {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={this.state.open} timeout="auto">
                    <List component="div" disablePadding key='categoriesSubList'>
                        {categories.map(category => <TaskCategory category={category} 
                                selectCategory={this.props.selectCategory}
                                deselectCategory={this.props.deselectCategory}/>)}
                    </List>
                </Collapse>
            </Box>
        );
    }
}

export default CategoriesList;
