import React from 'react';
import TodoSidebar from './sidebar/TodoSidebar';
import { format } from 'react-string-format';
import { APIS } from '../constants/apis';
import { call } from '../clients/apiClient';
import { Backdrop, Box, CircularProgress, CssBaseline } from '@mui/material';
import TodoAppBar from './appBar/TodoAppBar';
import ItemsList from './items/ItemsList';
import Personalisation from './settings/personalisation/Personalisation';

class TodoHome extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            labels: [],
            todos: [],
            currentView: 'home',
            loadingCategories: true,
            loadingLabels: true,
            loadingTodos: true,
            loading: true,
            drawerOpen: false
        }
        this.addQuickTodo = this.addQuickTodo.bind(this);
        this.deleteTodo = this.deleteTodo.bind(this);
        this.updateDrawerState = this.updateDrawerState.bind(this);
        this.setView = this.setView.bind(this);
    }

    componentDidMount() {
        this.setState({
            loading: true
        });
        this.refreshCategories();
        this.refreshLabels();
        this.refreshTodos();
        this.setState({
            loading: false
        });
    }

    refreshCategories = () => {

        this.setState({
            loadingCategories: true
        });

        var path = format(APIS.LIST_CATEGORIES.path);

        call(path, APIS.LIST_CATEGORIES.method, null)
            .then(response => {
                const body = response.json();
                return body;
            })
            .then((categories) => {
                this.setState({
                    categories: categories,
                    loadingCategories: false,
                    loading: (this.state.loadingLabels || this.state.loadingTodos)
                });
            }).catch((error) =>
                console.error(error)
            );
    }

    refreshLabels = () => {

        var path = format(APIS.LIST_LABELS.path);

        call(path, APIS.LIST_LABELS.method, null)
            .then(response => {
                const body = response.json();
                return body;
            })
            .then((labels) => {
                this.setState({
                    labels: labels,
                    loadingLabels: false,
                    loading: (this.state.loadingCategories || this.state.loadingTodos)
                });
            }).catch((error) =>
                console.error(error)
            );
    }

    refreshTodos = () => {

        var path = format(APIS.LIST_TODOS.path);

        call(path, APIS.LIST_TODOS.method, null)
            .then(response => {
                const body = response.json();
                return body;
            })
            .then((todos) => {
                this.setState({
                    todos: todos,
                    loadingTodos: false,
                    loading: (this.state.loadingCategories || this.state.loadingLabels)
                });
            }).catch((error) =>
                console.error(error)
            );
    }

    setView = (viewType) => {
        this.setState({
            currentView: viewType
        });
    }

    addCategory = (categoryName) => {
        var path = format(APIS.CREATE_CATEGORY.path);
        call(path, APIS.CREATE_CATEGORY.method, {
            "name": categoryName
        }).then(response => {
            return response.json();
        }).then(body => {
            this.setState({
                categories: [...this.state.categories, body]
            });
        }).catch((error) =>
            console.error(error)
        );
    }

    updateCategory = (categoryId, categoryName) => {
        var path = format(APIS.UPDATE_CATEGORY.path);
        call(path, APIS.UPDATE_CATEGORY.method, {
            "name": categoryName,
            "categoryId": categoryId
        }).then(response => {
            return response.json();
        }).then(body => {
            let updatedCategories = [...this.state.categories];
            let pos = updatedCategories.map(category => category.categoryId).indexOf(categoryId);
            updatedCategories.splice(pos, 1);
            updatedCategories.splice(pos, 0, body);
            this.setState({
                categories: updatedCategories
            });
        }).catch((error) =>
            console.error(error)
        );
    }

    deleteCategory = (categoryId) => {
        if (this.isCategoryInUse(categoryId)) {
            console.log("Category in use. Unable to delete...");
            return;
        }
        var path = format(APIS.DELETE_CATEGORY.path, categoryId);
        call(path, APIS.DELETE_CATEGORY.method, null).then(response => {
            let updatedCategories = [...this.state.categories];
            let pos = updatedCategories.map(category => category.categoryId).indexOf(categoryId);
            updatedCategories.splice(pos, 1);
            this.setState({
                categories: updatedCategories
            });
        }).catch((error) =>
            console.error(error)
        );
    }

    addLabel = (labelName) => {
        var path = format(APIS.CREATE_LABEL.path);
        call(path, APIS.CREATE_LABEL.method, {
            "name": labelName
        }).then(response => {
            return response.json();
        }).then(body => {
            this.setState({
                labels: [...this.state.labels, body]
            });
        }).catch((error) =>
            console.error(error)
        );
    }

    updateLabel = (labelId, labelName) => {
        var path = format(APIS.UPDATE_LABEL.path);
        call(path, APIS.UPDATE_LABEL.method, {
            "name": labelName,
            "labelId": labelId
        }).then(response => {
            return response.json();
        }).then(body => {
            let updatedLabels = [...this.state.labels];
            let pos = updatedLabels.map(label => label.labelId).indexOf(labelId);
            updatedLabels.splice(pos, 1);
            updatedLabels.splice(pos, 0, body);
            this.setState({
                labels: updatedLabels
            });
        }).catch((error) =>
            console.error(error)
        );
    }

    isLabelInUse(labelId) {
        return this.state.todos
            .filter(todo => todo.labels != null)
            .map(todo => todo.labels)
            .filter(labels => labels.includes(labelId))
            .length > 0;
    }

    isCategoryInUse(categoryId) {
        return this.state.todos.filter(todo => todo.categoryId === categoryId).length > 0;
    }

    deleteLabel = (labelId) => {
        if (this.isLabelInUse(labelId)) {
            console.log("Label in use. Unable to delete...");
            return;
        }
        var path = format(APIS.DELETE_LABEL.path, labelId);
        call(path, APIS.DELETE_LABEL.method, null).then(() => {
            let updatedLabels = [...this.state.labels];
            let pos = updatedLabels.map(label => label.labelId).indexOf(labelId);
            updatedLabels.splice(pos, 1);
            this.setState({
                labels: updatedLabels
            });
        }).catch((error) =>
            console.error(error)
        );
    }

    addQuickTodo = (title) => {
        var path = format(APIS.CREATE_TODO.path);
        call(path, APIS.CREATE_TODO.method, {
            "title": title,
            "description": '',
            "labels": []
        }).then(response => {
            return response.json();
        }).then(body => {
            this.setState({
                todos: [...this.state.todos, body]
            });
        }).catch((error) =>
            console.error(error)
        );
    }

    updateTodo = (updatedTodoItem) => {
        var path = format(APIS.UPDATE_TODO.path);
        call(path, APIS.UPDATE_TODO.method, updatedTodoItem).then(response => {
            return response.json();
        }).then(body => {
            let updatedTodos = [...this.state.todos];
            let pos = updatedTodos.map(todo => todo.todoId).indexOf(updatedTodoItem.todoId);
            updatedTodos.splice(pos, 1);
            updatedTodos.splice(pos, 0, body);
            this.setState({
                todos: updatedTodos
            });
        }).catch((error) =>
            console.error(error)
        );
    }

    deleteTodo = (todoId) => {
        var path = format(APIS.DELETE_TODO.path, todoId);
        call(path, APIS.DELETE_TODO.method, null).then(() => {
            let updatedTodos = [...this.state.todos];
            let pos = updatedTodos.map(todo => todo.todoId).indexOf(todoId);
            updatedTodos.splice(pos, 1);
            this.setState({
                todos: updatedTodos
            });
        }).catch((error) =>
            console.error(error)
        );
    }

    updateDrawerState = (open) => {
        this.setState({
            drawerOpen: open
        });
    }

    render() {
        const drawerWidth = 240;
        return (
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <TodoAppBar drawerWidth={drawerWidth} drawerOpen={this.state.drawerOpen}
                    updateDrawerState={this.updateDrawerState} />
                <TodoSidebar
                    drawerWidth={drawerWidth}
                    drawerOpen={this.state.drawerOpen}
                    setView={this.setView}
                    updateDrawerState={this.updateDrawerState} />
                {this.state.currentView === 'home' &&
                    <ItemsList todos={this.state.todos}
                        addQuickTodo={this.addQuickTodo}
                        updateTodo={this.updateTodo}
                        drawerWidth={drawerWidth}
                        categories={this.state.categories}
                        labels={this.state.labels}
                        drawerOpen={false}
                        deleteTodo={this.deleteTodo} />}
                {this.state.currentView === 'settings' &&
                    <Personalisation todos={this.state.todos}
                        drawerWidth={drawerWidth}
                        drawerOpen={false}
                        categories={this.state.categories}
                        labels={this.state.labels}
                        addCategory={this.addCategory}
                        updateCategory={this.updateCategory}
                        deleteCategory={this.deleteCategory}
                        addLabel={this.addLabel}
                        updateLabel={this.updateLabel}
                        deleteLabel={this.deleteLabel}
                    />
                }
            </Box>
        );
    }
}

export default TodoHome;
