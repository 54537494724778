import { BlockRounded } from "@mui/icons-material";
import { Checkbox, Drawer, FormControl, FormControlLabel, InputLabel, Link, MenuItem, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import getUnixTime from 'date-fns/getUnixTime';

class UpdateTodoDrawer extends React.Component {

    width = '30ch';

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            effort: 0,
            urgency: 'LOW',
            importance: 'LOW',
            categoryId: '',
            labels: [],
            blocked: false,
            completed: false,
            dueAt: null
        }
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    componentDidMount() {
        const { openUpdateTodoDrawer, todo } = this.props;
        if (openUpdateTodoDrawer) {
            let labels = todo.labels;
            if (labels === null) {
                labels = [];
            }
            this.setState({
                title: todo.title,
                description: todo.description,
                effort: todo.effort,
                urgency: todo.urgency,
                importance: todo.importance,
                blocked: todo.blocked,
                completed: todo.completed,
                categoryId: todo.categoryId,
                labels: labels,
                dueAt: todo.dueAt
            });
        }
    }

    componentDidUpdate(oldProps) {
        if (oldProps.openUpdateTodoDrawer !== this.props.openUpdateTodoDrawer &&
            JSON.stringify(oldProps.todo) !== JSON.stringify(this.props.todo)) {
            const { openUpdateTodoDrawer, todo } = this.props;
            if (openUpdateTodoDrawer) {
                let labels = todo.labels;
                if (labels === null) {
                    labels = [];
                }
                this.setState({
                    title: todo.title,
                    description: todo.description,
                    effort: todo.effort,
                    urgency: todo.urgency,
                    importance: todo.importance,
                    blocked: todo.blocked,
                    categoryId: todo.categoryId,
                    labels: labels,
                    dueAt: todo.dueAt,
                    completed: todo.completed
                });
            }
        }
    }

    closeDrawer() {
        const { title, description, effort, urgency, importance, blocked, completed, categoryId, labels, dueAt } = this.state;
        const { todoId } = this.props.todo;

        let updatedTodoItem = {
            title: title,
            description: description,
            effort: effort,
            urgency: urgency,
            importance: importance,
            blocked: blocked,
            completed: completed,
            todoId: todoId,
            categoryId: categoryId,
            labels: labels,
            dueAt: dueAt
        }
        this.props.toggleUpdateTodoDrawer(false, null, updatedTodoItem);
    }

    setDueAt(newValue) {
        this.setState({
            dueAt: getUnixTime(newValue*1000)
        });
    }

    getDueAt() {
        const { dueAt } = this.state;

        if (dueAt === null) {
            return null;
        }

        var dueAtDate = new Date(dueAt);
        return dueAtDate;
    }

    getLabels() {
        const { labels } = this.state;
        let labelsValue = labels;
        if (labels === null) {
            labelsValue = [];
        }
        return labelsValue;
    }

    getValueOrEmptyString(value) {
        if (value === null) {
            return '';
        }
        return value;
    }

    render() {
        if (!this.props.openUpdateTodoDrawer) {
            return null;
        }
        return (
            <Drawer
                anchor='right'
                open={this.props.openUpdateTodoDrawer}
                onClose={this.closeDrawer}
                sx={{ mt: 10 }}
            >
                <Box component='form' sx={{
                    width: 300,
                    p: 1,
                    '& .MuiTextField-root': { m: 1, width: this.width },
                }}>
                    <TextField
                        id="title-input"
                        label="Title"
                        type="text"
                        fullWidth
                        value={this.state.title}
                        onChange={(event) => {
                            this.setState({
                                title: event.target.value
                            });
                        }}
                    />
                    <TextField
                        id="description-input"
                        label="Description"
                        type="text"
                        multiline
                        fullWidth
                        value={this.getValueOrEmptyString(this.state.description)}
                        onChange={(event) => {
                            this.setState({
                                description: event.target.value
                            });
                        }}
                    />
                    <TextField
                        id="effort-input"
                        label="Effort"
                        type="number"
                        fullWidth
                        value={this.state.effort}
                        onChange={(event) => {
                            this.setState({
                                effort: event.target.valueAsNumber
                            });
                        }}
                    />
                    <Link inset href="https://todoist.com/productivity-methods/eisenhower-matrix" sx={{pl:1}}>Prioritization matrix</Link>
                    <FormControl sx={{ m: 1, width: this.width }}>
                        <InputLabel id="urgency-label">Urgency</InputLabel>
                        <Select
                            labelId="urgency-label"
                            label="Urgency"
                            id="urgency-select"
                            value={this.getValueOrEmptyString(this.state.urgency)}
                            onChange={(event) => {
                                this.setState({
                                    urgency: event.target.value
                                });
                            }}
                        >
                            <MenuItem value='LOW' sx={{ width: '100%' }}>Low</MenuItem>
                            <MenuItem value='HIGH' sx={{ width: '100%' }}>High</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: this.width }}>
                        <InputLabel id="importance-label">Importance</InputLabel>
                        <Select
                            labelId="importance-label"
                            id="importance-select"
                            value={this.getValueOrEmptyString(this.state.importance)}
                            label='Importance'
                            onChange={(event) => {
                                this.setState({
                                    importance: event.target.value
                                });
                            }}
                        >
                            <MenuItem value='LOW' sx={{ width: '100%' }}>Low</MenuItem>
                            <MenuItem value='HIGH' sx={{ width: '100%' }}>High</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: this.width }}>
                        <InputLabel id="category-label">Category</InputLabel>
                        <Select
                            labelId="category-label"
                            id="category-select"
                            value={this.getValueOrEmptyString(this.state.categoryId)}
                            label='Category'
                            sx={{
                                display: 'flex',
                                justifyContent: 'left'
                            }}
                            onChange={(event) => {
                                this.setState({
                                    categoryId: event.target.value
                                });
                            }}
                        >
                            {this.props.categories.map(
                                category => <MenuItem value={category.categoryId} sx={{ width: '100%' }}>{category.name}</MenuItem>
                            )}
                            <MenuItem value='NONE' sx={{ width: '100%' }}>None</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: this.width }}>
                        <InputLabel id="labels-label">Labels</InputLabel>
                        <Select
                            labelId="labels-label"
                            id="labels-select"
                            multiple
                            value={this.getLabels()}
                            label='Labels'
                            sx={{
                                justifyContent: 'left'
                            }}
                            onChange={(event) => {
                                this.setState({
                                    labels: event.target.value
                                });
                            }}
                        >
                            {this.props.labels.map(
                                label => <MenuItem value={label.labelId} sx={{ width: '100%' }}>{label.name}</MenuItem>
                            )}
                            <MenuItem value='NONE' sx={{ width: '100%' }}>None</MenuItem>
                        </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="Due at"
                            value={this.getDueAt()}
                            onChange={(newValue) => {
                                this.setDueAt(newValue);
                            }}
                        />
                    </LocalizationProvider>
                    <FormControlLabel label="Blocked"
                        control={<Checkbox
                            id="blocked-input"
                            icon={<BlockRounded />}
                            checkedIcon={<BlockRounded sx={{
                                color: "red"
                            }} />}
                            checked={this.state.blocked}
                            onChange={(event) => {
                                this.setState({
                                    blocked: event.target.checked
                                });
                            }}
                        />}
                    />
                    <FormControlLabel label="Completed"
                        control={<Checkbox
                            id="completed-input"
                            checked={this.state.completed}
                            onChange={(event) => {
                                this.setState({
                                    completed: event.target.checked
                                });
                            }}
                        />}
                    />
                </Box>
            </Drawer>
        );
    }
}

export default UpdateTodoDrawer;
