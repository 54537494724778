import React from "react";
import { Box, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemText, TextField } from "@mui/material";
import { DeleteForeverTwoTone, EditTwoTone, ExpandLessTwoTone, ExpandMoreTwoTone, SaveTwoTone } from "@mui/icons-material";

class Personalisation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'editableFields': [],
            'view': ''
        };
        this.flipEditableState = this.flipEditableState.bind(this);
        this.flipView = this.flipView.bind(this);
    }

    flipEditableState(field_id, field_type) {
        let pos = this.state.editableFields.indexOf(field_id);
        let updatedEditableFields = this.state.editableFields;
        if (pos === -1) {
            updatedEditableFields.push(field_id);
        } else {
            updatedEditableFields.splice(pos, 1);
            if (field_type === 'category') {
                this.props.updateCategory(field_id, document.getElementById(field_id).value);
            } else {
                this.props.updateLabel(field_id, document.getElementById(field_id).value);
            }
        }
        this.setState({
            editableFields: updatedEditableFields
        });
    }

    addCategory() {
        const addCategoryField = document.getElementById('new-category-field');
        this.props.addCategory(addCategoryField.value);
        addCategoryField.value = '';
    }

    deleteCategory(category_id) {
        this.props.deleteCategory(category_id);
    }

    addLabel() {
        const addLabelField = document.getElementById('new-label-field');
        this.props.addLabel(addLabelField.value);
        addLabelField.value = '';
    }

    deleteLabel(label_id) {
        this.props.deleteLabel(label_id);
    }

    flipView(viewName) {
        let newView = viewName === this.state.view ? "none" : viewName;
        this.setState({
            view: newView
        });
    }

    render() {
        return (
            <Box component='main'
                sx={{
                    flexGrow: 1,
                    pl: 3,
                    pr: 3,
                    pt: 10,
                    bgcolor: "navajowhite",
                    height: `100vh`,
                    ml: 0
                }}>
                <Box>
                    <List sx={{ width: '100%' }} disablePadding>
                        <ListItemButton key="categories"
                            sx={{
                                width: '100%'
                            }}
                            onClick={() => this.flipView("categories")}>
                            <ListItemText primary="Categories" />
                            {this.state.view === 'categories' ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />}
                        </ListItemButton>
                        {this.state.view === 'categories' && this.props.categories.map(category =>
                            <ListItem key={category.categoryId} sx={{ border: 0, width: 'inherit' }}>
                                <TextField width="90%" id={category.categoryId}
                                    disabled={!this.state.editableFields.includes(category.categoryId)}
                                    InputProps={{
                                        readOnly: !this.state.editableFields.includes(category.categoryId),
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton onClick={() => this.flipEditableState(category.categoryId, 'category')}>
                                                {this.state.editableFields.includes(category.categoryId) ? <SaveTwoTone /> : <EditTwoTone />}
                                            </IconButton>
                                            <IconButton onClick={() => this.deleteCategory(category.categoryId)}>
                                                <DeleteForeverTwoTone />
                                            </IconButton>
                                        </InputAdornment>
                                    }} defaultValue={category.name}
                                    sx={{ width: 'inherit' }} />
                            </ListItem>
                        )}
                        {this.state.view === 'categories' && <ListItem key='new-category-item' sx={{ border: 0, width: 'inherit' }}>
                            <TextField width="90%" id='new-category-field'
                                label="Add category"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton onClick={() => this.addCategory()}>
                                            <SaveTwoTone />
                                        </IconButton>
                                    </InputAdornment>
                                }}
                                sx={{ width: 'inherit' }} />
                        </ListItem>}
                        <ListItemButton key="labels"
                            onClick={() => this.flipView("labels")}
                            sx={{
                                width: '100%'
                            }}>
                            <ListItemText primary="Labels" />
                            {this.state.view === 'labels' ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />}
                        </ListItemButton>
                        {this.state.view === 'labels' && this.props.labels.map(label =>
                            <ListItem key={label.labelId} sx={{ border: 0, width: 'inherit' }}>
                                <TextField width="90%" disabled={!this.state.editableFields.includes(label.labelId)}
                                    id={label.labelId}
                                    InputProps={{
                                        readOnly: !this.state.editableFields.includes(label.labelId),
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton onClick={() => this.flipEditableState(label.labelId, 'label')}>
                                                {this.state.editableFields.includes(label.labelId) ? <SaveTwoTone /> : <EditTwoTone />}
                                            </IconButton>
                                            <IconButton onClick={() => this.deleteLabel(label.labelId)}>
                                                <DeleteForeverTwoTone />
                                            </IconButton>
                                        </InputAdornment>
                                    }} defaultValue={label.name}
                                    sx={{ width: 'inherit' }} />
                            </ListItem>
                        )}
                        {this.state.view === 'labels' && <ListItem key='new-label-item' sx={{ border: 0, width: 'inherit' }}>
                            <TextField width="90%" id='new-label-field'
                                label="Add label"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton onClick={() => this.addLabel()}>
                                            <SaveTwoTone />
                                        </IconButton>
                                    </InputAdornment>
                                }}
                                sx={{ width: 'inherit' }} />
                        </ListItem>}
                    </List>
                </Box>
            </Box>
        );
    }

}

export default Personalisation;