import { format } from 'react-string-format';
import { ACCESS_TOKEN } from '../constants/apis';

export const METHOD = {
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE'
}

export const call = (path, method, requestBody) => {
    const BACKEND_URL = "https://api.bugdivine.dev/pmt";
    var url = format("{0}{1}", BACKEND_URL, path);
    var authorization_header = localStorage.getItem(ACCESS_TOKEN);
    if (authorization_header === null) {
        console.log("Missing authentication data. Redirecting to login...");
        window.location.replace(getAuthenticationUrl());
        return;
    }
    var headers = {
        'Content-Type': 'application/json',
        'Authorization': authorization_header
    };
    const requestOptions = {
        method: method,
        headers: headers
    };
    if (method !== METHOD.GET) {
        requestOptions.body = JSON.stringify(requestBody);
    }
    const response = fetch(url, requestOptions).then(response => {
        if (response.status === 403 || response.status === 401) {
            console.log("Received 401/403, Redirecting...");
            window.location.replace(getAuthenticationUrl());
            throw new Error("Received forbidden error...");
        } else {
            return response;
        }
    });
    return response;
}

export function getAuthenticationUrl() {
    const login_page = "https://auth.bugdivine.dev/login";
    const client_id = "7of3m8be2q15q067f9upkojjlu";
    const response_type = "token";
    const scope = "api.bugdivine.dev/ptm.call";
    const redirect_url = window.location.protocol + "//" + window.location.host;
    return format("{0}?client_id={1}&response_type={2}&scope={3}&redirect_uri={4}",
        login_page, client_id, response_type, scope, redirect_url);
}
