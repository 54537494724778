import React from "react";
import { FcHighPriority, FcLowPriority, FcMediumPriority } from "react-icons/fc";

class ImportanceIcon extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const importance = this.props.importance;
        return (
            <div>
                {importance === "HIGH" && <FcHighPriority title="Importance: High" />}
                {importance === "MEDIUM" && <FcMediumPriority title="Importance: Medium" />}
                {importance === "LOW" && <FcLowPriority title="Importance: Low" />}
            </div>
        );
    }
}

export default ImportanceIcon;
