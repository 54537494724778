import React from 'react';
import TaskLabel from './TaskLabel';
import { ListItemIcon, ListItemText, Collapse, List } from '@material-ui/core';
import { ExpandLess, ExpandMore, LabelRounded } from '@material-ui/icons';
import { Box, ListItemButton } from '@mui/material';

class LabelsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = () => {
        this.setState({
            open: !this.state.open
        });
    };

    render() {
        const { labels } = this.props;
        return (
            <Box sx={{ pl: 2 }}>
                <ListItemButton onClick={this.handleClick} key='labelsSidebar'
                    sx={{ width: '100%' }}>
                    <ListItemIcon>
                        <LabelRounded />
                    </ListItemIcon>
                    <ListItemText primary="Labels" />
                    {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={this.state.open} timeout="auto">
                    <List component="div" disablePadding key='labelsSubList'>
                        {labels.map(label => <TaskLabel label={label}
                            selectLabel={this.props.selectLabel}
                            deselectLabel={this.props.deselectLabel} />)}
                    </List>
                </Collapse>
            </Box>
        );
    }
}

export default LabelsList;
