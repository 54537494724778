import React from 'react';
import { Box } from "@mui/system";
import TodoItem from './TodoItem';
import { IconButton, InputAdornment, List, TextField } from '@mui/material';
import { AddCircleOutlineRounded } from '@material-ui/icons';
import UpdateTodoDrawer from './UpdateTodoDrawer';
import FilterPopover from './FilterPopover';

class ItemsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            updateTodo: null,
            openUpdateTodoDrawer: false,
            selectedCategories: [],
            selectedLabels: [],
        };
        this.getTodos = this.getTodos.bind(this);
        this.getTodo = this.getTodo.bind(this);
        this.addQuickTodo = this.addQuickTodo.bind(this);
        this.addTodoField = this.addTodoField.bind(this);
        this.toggleUpdateTodoDrawer = this.toggleUpdateTodoDrawer.bind(this);
    }

    URGENCY_VALUES = {
        "HIGH": 3,
        "MEDIUM": 2,
        "LOW": 1
    }

    IMPORTANCE_VALUES = {
        "HIGH": 3,
        "MEDIUM": 2,
        "LOW": 1
    }

    selectCategory = (categoryId) => {
        this.setState({
            selectedCategories: [...this.state.selectedCategories, categoryId]
        });
    }

    deselectCategory = (categoryId) => {
        let newSelectedCategories = [...this.state.selectedCategories];
        let pos = newSelectedCategories.indexOf(categoryId);
        newSelectedCategories.splice(pos, 1)
        this.setState({
            selectedCategories: newSelectedCategories
        });
    }

    selectLabel = (labelId) => {
        this.setState({
            selectedLabels: [...this.state.selectedLabels, labelId]
        });
    }

    deselectLabel = (labelId) => {
        let newSelectedLabels = [...this.state.selectedLabels];
        let pos = newSelectedLabels.indexOf(labelId);
        newSelectedLabels.splice(pos, 1)
        this.setState({
            selectedLabels: newSelectedLabels
        });
    }

    getFilteredTodos() {
        const { selectedCategories, selectedLabels } = this.state;
        const { todos } = this.props;

        let filteredTodos = JSON.parse(JSON.stringify(todos));
        if (selectedCategories.length !== 0) {
            filteredTodos = filteredTodos.filter(todo =>
                selectedCategories.includes(todo.categoryId)
            );
        }

        if (selectedLabels.length !== 0) {
            filteredTodos = filteredTodos.filter(todo => {
                if (todo.labels === null) {
                    return false;
                } else {
                    return selectedLabels.filter(selectedLabel => todo.labels.includes(selectedLabel)).length > 0;
                }
            });
        }

        return filteredTodos;
    }

    getTodos() {
        let todos = this.getFilteredTodos();
        todos.sort((a, b) => {
            let completedStatus = this.compareCompletedStatus(a, b);
            if (completedStatus === 0) {
                let importance = this.compareImportance(a, b);
                if (importance === 0) {
                    let urgency = this.compareUrgency(a, b);
                    if (urgency === 0) {
                        return this.compareEffort(a, b);
                    }
                    return urgency;
                }
                return importance;
            }
            return completedStatus;
        });
        return todos;
    }

    compareCompletedStatus(a, b) {
        if (!('completed' in a) || a['completed'] === null) {
            return 1;
        }
        if (!('completed' in b) || b['completed'] === null) {
            return -1;
        }
        if (a['completed'] && b['completed']) {
            return 0;
        }
        if (a['completed']) {
            return 1;
        }
        if (!a['completed'] && !b['completed']) {
            return 0;
        }
        return -1;
    }

    compareEffort(a, b) {
        if (!('effort' in a) || a['effort'] == null) {
            return 1;
        }
        if (!('effort' in b) || b['effort'] == null) {
            return -1;
        }
        return b['effort'] - a['effort'];
    }

    compareUrgency(urgencyA, urgencyB) {
        if (!('urgency' in urgencyA) || urgencyA['urgency'] === null) {
            return 1;
        }
        if (!('urgency' in urgencyB) || urgencyB['urgency'] === null) {
            return -1;
        }
        let urgencyANum = this.URGENCY_VALUES[urgencyA['urgency']];
        let urgencyBNum = this.URGENCY_VALUES[urgencyB['urgency']];
        return urgencyBNum - urgencyANum;
    }

    compareImportance(importanceA, importanceB) {
        if (!('importance' in importanceA) || importanceA['importance'] === null) {
            return 1;
        }
        if (!('importance' in importanceB) || importanceB['importance'] === null) {
            return -1;
        }
        let importanceANum = this.URGENCY_VALUES[importanceA['importance']];
        let importanceBNum = this.URGENCY_VALUES[importanceB['importance']];
        return importanceBNum - importanceANum;
    }

    addQuickTodo() {
        var textField = document.getElementById("quickAddTodoText");
        const title = textField.value;
        if (title === "") {
            return;
        }
        this.props.addQuickTodo(title);
        textField.value = "";
    }

    addTodoField() {
        return (
            <TextField fullWidth label="Add todo" id="quickAddTodoText" InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton onClick={this.addQuickTodo} title='Add todo'>
                            <AddCircleOutlineRounded />
                        </IconButton>
                    </InputAdornment>
                )
            }} sx={{
                mb: 0,
                paddingTop: 1,
            }} />
        );
    }

    toggleUpdateTodoDrawer(openDrawer, todoId, updatedTodoItem) {
        if (openDrawer) {
            const todo = this.getTodo(todoId);
            this.setState({
                updateTodo: todo,
                openUpdateTodoDrawer: true
            });
        } else {
            this.setState({
                updateTodo: null,
                openUpdateTodoDrawer: false
            });
            this.props.updateTodo(updatedTodoItem);
        }
    }

    getTodo(todoId) {
        let todos = this.getTodos();
        let pos = todos.map(todo => todo.todoId).indexOf(todoId);
        return todos[pos];
    }

    render() {
        return (
            <Box component='main'
                sx={{
                    bgcolor: 'navajowhite',
                    pl: 3,
                    pr: 3,
                    pt: 8,
                    mb: 0,
                    right: 0,
                    left: 0,
                    width: '100%',
                    minHeight: '100vh'
                }}>
                <Box zIndex={100} sx={{
                    position: 'fixed',
                    right: 0,
                    left: 0,
                    pl: 3,
                    width: '100%',
                    pr: 3
                }}>
                    <FilterPopover categories={this.props.categories}
                        labels={this.props.labels}
                        selectCategory={this.selectCategory}
                        selectLabel={this.selectLabel}
                        deselectCategory={this.deselectCategory}
                        deselectLabel={this.deselectLabel}
                    />
                </Box>
                <Box sx={{
                    pt: 2,
                    mb: 0,
                    mt: 0,
                    overflow: 'auto',
                    pb: 8,
                }}>
                    <List sx={{
                        width: '100%',
                        overflow: 'auto',
                        height: 'auto',
                        bgColor: 'background.paper'
                    }}
                        component="nav">
                        {this.getTodos().map(todo => {
                            return <TodoItem todo={todo}
                                deleteTodo={this.props.deleteTodo}
                                updateTodo={this.props.updateTodo}
                                toggleUpdateTodoDrawer={this.toggleUpdateTodoDrawer} />
                        })}
                    </List>
                </Box>
                <Box sx={{
                    position: 'fixed',
                    bottom: 0,
                    bgcolor: 'navajowhite',
                    right: 0,
                    left: 0,
                    pl: 3,
                    pr: 3,
                    pb: 1
                }}>
                    {this.addTodoField()}
                </Box>
                <UpdateTodoDrawer todo={this.state.updateTodo}
                    openUpdateTodoDrawer={this.state.openUpdateTodoDrawer}
                    categories={this.props.categories}
                    labels={this.props.labels}
                    toggleUpdateTodoDrawer={this.toggleUpdateTodoDrawer} />
            </Box>
        );
    }
}

export default ItemsList;
