import React from 'react';
import { ListItemButton, ListItemText } from '@mui/material';

class TaskCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: false
        };
        this.getCategoryId = this.getCategoryId.bind(this);
        this.getCategoryName = this.getCategoryName.bind(this);
        this.flipSelected = this.flipSelected.bind(this);
    }

    getCategoryId() {
        const { category } = this.props;
        return category.categoryId;
    }

    getCategoryName() {
        const { category } = this.props;
        return category.name;
    }

    flipSelected() {
        if (this.state.selected) {
            this.props.deselectCategory(this.getCategoryId());
        } else {
            this.props.selectCategory(this.getCategoryId());
        }
        this.setState({
            selected: !this.state.selected
        });
    }

    render() {
        return (
            <ListItemButton key={this.getCategoryId()}
                sx={{ pl: 4, width: '100%' }}
                onClick={this.flipSelected}
                selected={this.state.selected}>
                <ListItemText inset primary={this.getCategoryName()} />
            </ListItemButton>
        );
    }
}

export default TaskCategory;
