import { Checkbox } from "@material-ui/core";
import { BlockTwoTone, DeleteForeverTwoTone } from "@mui/icons-material";
import { IconButton, ListItemButton, ListItemText, Paper } from "@mui/material";
import React from "react";
import ImportanceIcon from "../icons/ImportanceIcon";
import UrgencyIcon from "../icons/UrgencyIcon";

class TodoItem extends React.Component {

    constructor(props) {
        super(props);
        this.deleteTodo = this.deleleTodo.bind(this);
        this.getShortDescription = this.getShortDescription.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.markCompleted = this.markCompleted.bind(this);
        this.getCheckboxTitle = this.getCheckboxTitle.bind(this);
    }

    markCompleted(event) {
        let todo = this.props.todo;
        event.stopPropagation();
        todo.completed = event.target.checked;
        this.props.updateTodo(todo);
    }

    deleleTodo(event) {
        const todoId = this.props.todo.todoId;
        event.stopPropagation();
        this.props.deleteTodo(todoId);
    }

    getShortDescription() {
        const description = this.props.todo.description;
        if (!description) {
            return "";
        }
        return description.substring(0, 30);
    }

    handleClick() {
        const todoId = this.props.todo.todoId;
        this.props.toggleUpdateTodoDrawer(true, todoId, null);
    }

    getCheckboxTitle() {
        const isCompleted = this.props.todo.completed;
        if (isCompleted) {
            return "Mark not completed";
        } else {
            return "Mark completed";
        }
    }

    render() {
        return (
            <Paper elevation={1} sx={{
                mt: 1
            }}>
                <ListItemButton onClick={this.handleClick}
                    key={this.props.todo.todoId}
                    sx={{
                        width: '100%'
                    }}>
                    <Checkbox checked={this.props.todo.completed} onClick={this.markCompleted}
                        title={this.getCheckboxTitle()} />
                    <ListItemText primary={this.props.todo.title} secondary={
                        <React.Fragment>
                            {this.getShortDescription()}
                        </React.Fragment>
                    } />
                    {this.props.todo.blocked && <BlockTwoTone sx={{
                        color: "red"
                    }} />}
                    <UrgencyIcon urgency={this.props.todo.urgency} sx={{
                        align: "right"
                    }} />
                    <ImportanceIcon importance={this.props.todo.importance} sx={{
                        align: "right"
                    }} />
                    <IconButton onClick={this.deleteTodo} title="Delete todo">
                        <DeleteForeverTwoTone />
                    </IconButton>
                </ListItemButton >
            </Paper>
        );
    }

}

export default TodoItem;
