import { HourglassBottomTwoTone, HourglassEmptyTwoTone, HourglassTopTwoTone } from "@mui/icons-material";
import React from "react";

class UrgencyIcon extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const urgency = this.props.urgency;
        return (
            <div>
                {urgency === "HIGH" && <HourglassBottomTwoTone titleAccess="Urgency: High" />}
                {urgency === "MEDIUM" && <HourglassEmptyTwoTone titleAccess="Urgency: Medium" />}
                {urgency === "LOW" && <HourglassTopTwoTone titleAccess="Urgency: Low" />}
            </div>
        );
    }
}

export default UrgencyIcon;
